import React, { ReactNode, useEffect, useRef, useState } from "react"
import { Provider as JotaiProvider } from "jotai"
import { FeatureCheck } from "./FeatureCheck"
import { ClientConfigLoader } from "../components/ClientConfigLoader"
import { ConfigConfigError, ConfigConfigLoading } from "./ConfigConfig"
import { ClientConfigProvider } from "../hooks/useClientConfig"
import "../index.scss"

import "@fontsource/inter/variable.css"
import "folds/dist/style.css"
import { configClass, varsClass } from "folds"
import settings from "../client/state/settings"
import { ChatWrapperProvider } from "./ChatWrapper2Context"
import navigation from "../client/state/navigation"
import cons from "../client/state/cons"
import { initHotkeys } from "../client/event/hotkeys"
import { initRoomListListener } from "../client/event/roomList"
import Text from "../atoms/text/Text"
import Spinner from "../atoms/spinner/Spinner"
import ContextMenu, { MenuItem } from "../atoms/context-menu/ContextMenu"
import IconButton from "../atoms/button/IconButton"
import initMatrix from "../client/initMatrix"
import {
  toggleSystemTheme,
  toggleNotifications,
  toggleNotificationSounds
} from "../client/action/settings"
import { MatrixClientProvider } from "../hooks/useMatrixClient"
import ReusableContextMenu from "../atoms/context-menu/ReusableContextMenu"
import Windows from "../organisms/pw/Windows"
import Dialogs from "../organisms/pw/Dialogs"
import { useSetting } from "../state/hooks/settings"
import { useSelectedTab } from "../hooks/useSelectedTab"
import { settingsAtom } from "../state/settings"
import Client from "../templates/client/Client"
import { useLocation, useNavigate } from "react-router-dom"
import { selectRoom } from "../client/action/navigation"
import { get } from "../../../services/api"

document.body.classList.add(configClass, varsClass)
settings.applyTheme()

function SystemEmojiFeature() {
  const [twitterEmoji] = useSetting(settingsAtom, "twitterEmoji")

  if (twitterEmoji) {
    document.documentElement.style.setProperty("--font-emoji", "Twemoji")
  } else {
    document.documentElement.style.setProperty(
      "--font-emoji",
      "Twemoji_DISABLED"
    )
  }

  return null
}

const ChatWrapper2 = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const mx = initMatrix.matrixClient

  const [showChat, setShowChat] = useState(false)
  const [userPresence, setUserPresence] = useState()
  const [showThreadPanel, setShowThreadPanel] = useState(false)
  const [selectedTab] = useSelectedTab()
  const classNameHidden = "client__item-hidden"

  const navWrapperRef = useRef(null)
  const roomWrapperRef = useRef(null)

  function onRoomSelected() {
    navWrapperRef.current?.classList.add(classNameHidden)
    roomWrapperRef.current?.classList.remove(classNameHidden)
  }
  function onNavigationSelected() {
    navWrapperRef.current?.classList.remove(classNameHidden)
    roomWrapperRef.current?.classList.add(classNameHidden)
  }

  useEffect(() => {
    navigation.on(cons.events.navigation.ROOM_SELECTED, onRoomSelected)
    navigation.on(
      cons.events.navigation.NAVIGATION_OPENED,
      onNavigationSelected
    )

    return () => {
      navigation.removeListener(
        cons.events.navigation.ROOM_SELECTED,
        onRoomSelected
      )
      navigation.removeListener(
        cons.events.navigation.NAVIGATION_OPENED,
        onNavigationSelected
      )
    }
  }, [])

  const chatLoadingRef = useRef()
  const [isChatLoading, changeLoading] = useState(true)
  const [loadingMsg, setLoadingMsg] = useState("Heating up")
  const [selectedUser, setSelectedUser] = useState([])
  const [, updateState] = useState({})
  const [isThreadOpen, setIsThreadOpen] = useState(false)
  const [threadEventId, setThreadEventId] = useState("")
  const [isErrorState, setIsErrorState] = useState(false)
  const [dmNoti, setDmNoti] = useState({})
  const [homeNoti, setHomeNoti] = useState({})
  const [favDMNoti, setFavDmNoti] = useState({})
  const [favHomeNoti, setFavHomeNoti] = useState({})
  const { roomList } = initMatrix
  const [totalInvites, setTotalInvites] = useState(0)
  const [usersSmsNumbers, setUsersSmsNumbers] = useState([])
  // roomList?.inviteRooms.size +
  //   roomList?.inviteSpaces.size +
  //   roomList?.inviteDirects.size

  useEffect(() => {
    chatLoadingRef.current = isChatLoading
  }, [isChatLoading])

  const readThread = async (count, postId, room) => {
    if (room) {
      if (count > 0) {
        const event = room.getThreads().find((thread) => thread.id === postId)
        mx?.sendReadReceipt(event?.timeline?.at(-1))
          .then((response) => {})
          .catch((error) => console.warn(error))
      }
    }
  }

  useEffect(() => {
    changeLoading(true)
    let counter = 0
    const iId = setInterval(() => {
      const msgList = [
        "Almost there...",
        "Looks like you have a lot of stuff to heat up!"
      ]
      if (counter === msgList.length - 1) {
        setLoadingMsg(msgList[msgList.length - 1])
        clearInterval(iId)
        return
      }
      setLoadingMsg(msgList[counter])
      counter += 1
    }, 15000)

    initMatrix.once("init_loading_finished", () => {
      clearInterval(iId)
      initHotkeys()
      initRoomListListener(initMatrix.roomList)
      changeLoading(false)

      initMatrix.notifications.on("notifications_click", (room, mEvent) => {
        const roomType = room.getType()
        selectRoom(room.roomId, mEvent.getId())

        navigate("/chat")

        if (roomType === "Channels") {
          localStorage.setItem("last_used_tab", "Channels")
          localStorage.setItem("channel_roomid", room.roomId)

          if (mEvent.getContent()?.["m.relates_to"]?.rel_type === "m.thread") {
            readThread(1, mEvent.getId(), room)
            setIsThreadOpen(true)
            setThreadEventId(mEvent.getContent()?.["m.relates_to"]?.event_id)
          }
        } else {
          localStorage.setItem("last_used_tab", "DM")
          localStorage.setItem("dm_roomid", room.roomId)
        }
      })
    })

    initMatrix.once("init_loading_error", () => {
      setIsErrorState(true)
    })

    initMatrix.once("init_syncing", () => {
      if (!isErrorState && isChatLoading) {
        changeLoading(false)
      }
      if (isErrorState) {
        setIsErrorState(false)
      }
    })
    initMatrix.init()

    setTimeout(() => {
      if (chatLoadingRef.current) {
        setIsErrorState(true)
      }
    }, 30000)

    async function getUsersSmsNumbers() {
      try {
        const usersNumbers = await get("/api/v1/chat/get-user-numbers/")
        setUsersSmsNumbers(usersNumbers?.data?.data)
      } catch (error) {
        console.warn({ error })
      }
    }

    getUsersSmsNumbers()
  }, [])

  function getHomeNoti() {
    const { roomList, accountData, notifications } = initMatrix
    const orphans = roomList.getOrphans()
    let noti: { total: any; highlight: any } | null = null

    orphans.forEach((roomId: any) => {
      if (accountData.spaceShortcut.has(roomId)) return
      if (!notifications.hasNoti(roomId)) return
      if (noti === null) noti = { total: 0, highlight: 0 }
      const childNoti = notifications.getNoti(roomId)
      if (childNoti.total > 0) noti.total += 1
    })
    setHomeNoti(noti)
  }
  function getDMsNoti() {
    const { roomList, accountData, notifications } = initMatrix
    if (roomList.directs.size === 0) return null
    let noti: { total: any; highlight: any } | null = null

    ;[...roomList.directs].forEach((roomId) => {
      if (!notifications.hasNoti(roomId)) return
      if (noti === null) noti = { total: 0, highlight: 0 }
      const childNoti = notifications.getNoti(roomId)
      if (childNoti.total > 0) noti.total += 1
    })

    setDmNoti(noti)
  }

  // notifications of pinned DM chats
  function getFavsNotiDM() {
    const { roomList, accountData, notifications } = initMatrix
    if (roomList.favouritesDM.size === 0) return null
    let noti: { total: any; highlight: any } | null = null

    ;[...roomList.favouritesDM].forEach((roomId) => {
      if (!notifications.hasNoti(roomId)) return
      if (noti === null) noti = { total: 0, highlight: 0 }
      const childNoti = notifications.getNoti(roomId)
      if (childNoti.total > 0) noti.total += 1
    })

    setFavDmNoti(noti)
  }

  // notifications of pinned Channel chats
  function getFavsNotiChannel() {
    const { roomList, accountData, notifications } = initMatrix
    if (roomList.favouritesChannel.size === 0) return null
    let noti: { total: any; highlight: any } | null = null

    ;[...roomList.favouritesChannel].forEach((roomId) => {
      if (!notifications.hasNoti(roomId)) return
      if (noti === null) noti = { total: 0, highlight: 0 }
      const childNoti = notifications.getNoti(roomId)
      if (childNoti.total > 0) noti.total += 1
    })

    setFavHomeNoti(noti)
  }

  useEffect(() => {
    if (showThreadPanel) {
      setIsThreadOpen(false)
    }
  }, [showThreadPanel])

  useEffect(() => {
    if (isThreadOpen) {
      setShowThreadPanel(false)
    }
  }, [isThreadOpen])

  useEffect(() => {
    if (!isChatLoading) {
      getDMsNoti()
      getHomeNoti()
      getFavsNotiDM()
      getFavsNotiChannel()

      const { roomList } = initMatrix
      const onInviteListChange = () => {
        setTotalInvites(
          roomList.inviteRooms.size +
            roomList.inviteSpaces.size +
            roomList.inviteDirects.size
        )
      }
      roomList.on(cons.events.roomList.INVITELIST_UPDATED, onInviteListChange)
      onInviteListChange()
      return () => {
        roomList.removeListener(
          cons.events.roomList.INVITELIST_UPDATED,
          onInviteListChange
        )
      }
    }
  }, [isChatLoading])

  if (isChatLoading) {
    if (location.pathname?.includes("chat")) {
      return (
        <div className="loading-display">
          <div className="loading__menu">
            <ContextMenu
              placement="bottom"
              content={
                <>
                  <MenuItem onClick={() => initMatrix.clearCacheAndReload()}>
                    Clear cache & reload
                  </MenuItem>
                  <MenuItem onClick={() => initMatrix.logout()}>
                    Logout
                  </MenuItem>
                </>
              }
              render={(toggle) => (
                <IconButton
                  size="extra-small"
                  onClick={toggle}
                  src={<div>Icon</div>}
                />
              )}
            />
          </div>
          <Spinner />
          <Text className="loading__message" variant="b2">
            {loadingMsg}
          </Text>

          {/* <div className="loading__appname">
          <Text variant="h2" weight="medium">
            Cinny
          </Text>
        </div> */}
        </div>
      )
    }
    // return React.cloneElement(children, {
    //   isChatLoading: true
    // })
  }

  return (
    <FeatureCheck>
      <ClientConfigProvider
        value={{
          defaultHomeserver: 2,
          homeserverList: [
            "converser.eu",
            "envs.net",
            "matrix.org",
            "monero.social",
            "mozilla.org",
            "xmr.se"
          ],
          allowCustomHomeservers: true,
          hashRouter: {
            enabled: false,
            basename: "/"
          }
        }}
      >
        <JotaiProvider>
          <ChatWrapperProvider
            value={{
              navWrapperRef,
              roomWrapperRef,
              selectedUser,
              setSelectedUser,
              isThreadOpen,
              setIsThreadOpen,
              threadEventId,
              setThreadEventId,
              selectedTab,
              navigation,
              showChat,
              setShowChat,
              isErrorState,
              dmNoti,
              homeNoti,
              favDMNoti,
              favHomeNoti,
              totalInvites,
              userPresence,
              setUserPresence,
              readThread,
              showThreadPanel,
              setShowThreadPanel,
              usersSmsNumbers,
              setUsersSmsNumbers
            }}
          >
            <MatrixClientProvider value={initMatrix.matrixClient}>
              {React.cloneElement(children, {
                isChatLoading
              })}
              {!isChatLoading && (
                <>
                  <Windows />
                  <Dialogs />
                  <ReusableContextMenu />
                  <SystemEmojiFeature />
                </>
              )}
            </MatrixClientProvider>
          </ChatWrapperProvider>
        </JotaiProvider>
      </ClientConfigProvider>
      {/* )}
      </ClientConfigLoader> */}
    </FeatureCheck>
  )
}

export default ChatWrapper2
