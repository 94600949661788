import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./InviteList.scss"

import { useNavigate } from "react-router-dom"
import initMatrix from "../../client/initMatrix"
import cons from "../../client/state/cons"
import * as roomActions from "../../client/action/room"
import { selectRoom, selectTab } from "../../client/action/navigation"

import Text from "../../atoms/text/Text"
import Button from "../../atoms/button/Button"
import IconButton from "../../atoms/button/IconButton"
import Spinner from "../../atoms/spinner/Spinner"
import PopupWindow from "../../molecules/popup-window/PopupWindow"
import RoomTile from "../../molecules/room-tile/RoomTile"

import CrossIC from "../../public/res/ic/outlined/cross.svg"

function InviteList({ isOpen, onRequestClose }) {
  const [procInvite, changeProcInvite] = useState(new Set())
  const navigate = useNavigate()
  const inviteDirects = initMatrix?.roomList?.inviteDirects
  const inviteSpaces = initMatrix?.roomList?.inviteSpaces
  const inviteRooms = initMatrix?.roomList?.inviteRooms

  function acceptInvite(roomId, isDM) {
    const mx = initMatrix.matrixClient
    const room = mx.getRoom(roomId)
    const roomTypes = room.getType()
    const isSMS =
      roomTypes === cons.roomType.SMSMMS_GROUPS ||
      roomTypes === cons.roomType.SMSMMS_DM
    navigate("/chat")
    procInvite.add(roomId)
    changeProcInvite(new Set(Array.from(procInvite)))
    roomActions.join(roomId, isDM, undefined)
    setTimeout(() => {
      if (roomTypes === "Channels") {
        localStorage.setItem("last_used_tab", "Channels")
        localStorage.setItem("channel_roomid", roomId)
        selectTab(cons.tabs.HOME)
        selectRoom(roomId)
      } else {
        localStorage.setItem("last_used_tab", "DM")
        localStorage.setItem("dm_roomid", roomId)
        selectTab(cons.tabs.DIRECTS)
        selectRoom(roomId)
      }
    }, 2000)
  }
  function rejectInvite(roomId, isDM) {
    procInvite.add(roomId)
    changeProcInvite(new Set(Array.from(procInvite)))
    roomActions.leave(roomId, isDM)
  }
  function updateInviteList(roomId) {
    if (procInvite.has(roomId)) procInvite.delete(roomId)
    changeProcInvite(new Set(Array.from(procInvite)))

    const rl = initMatrix.roomList
    const totalInvites =
      rl.inviteDirects.size + rl.inviteRooms.size + rl.inviteSpaces.size
    const room = initMatrix.matrixClient.getRoom(roomId)
    const isRejected = room === null || room?.getMyMembership() !== "join"
    if (!isRejected) {
      // if (room.isSpaceRoom()) selectTab(roomId)
      // else selectRoom(roomId)
      onRequestClose()
    }
    if (totalInvites === 0) onRequestClose()
  }

  useEffect(() => {
    initMatrix.roomList.on(
      cons.events.roomList.INVITELIST_UPDATED,
      updateInviteList
    )

    return () => {
      initMatrix.roomList.removeListener(
        cons.events.roomList.INVITELIST_UPDATED,
        updateInviteList
      )
    }
  }, [procInvite])

  function renderRoomTile(roomId) {
    const mx = initMatrix.matrixClient
    const myRoom = mx.getRoom(roomId)
    if (!myRoom) return null
    const roomName = myRoom.name
    let roomAlias = myRoom.getCanonicalAlias()
    if (!roomAlias) roomAlias = myRoom.roomId
    const inviterId = myRoom.getCreator()
    const inviterName = mx.getUser(inviterId)?.rawDisplayName
   
    return (
      <RoomTile
        key={myRoom.roomId}
        name={myRoom.roomId}
        avatarSrc={initMatrix.matrixClient
          .getRoom(roomId)
          .getAvatarUrl(initMatrix.matrixClient.baseUrl, 42, 42, "crop")}
        id={roomAlias}
        inviterName={inviterName}
        options={
          procInvite.has(myRoom.roomId) ? (
            <Spinner size="small" />
          ) : (
            <div className="invite-btn__container">
              <Button onClick={() => rejectInvite(myRoom.roomId)}>
                Reject
              </Button>
              <Button
                onClick={() => acceptInvite(myRoom.roomId)}
                variant="primary"
              >
                Accept
              </Button>
            </div>
          )
        }
      />
    )
  }
  return (
    <PopupWindow
      isOpen={isOpen}
      title="Invites"
      contentOptions={
        <IconButton src={CrossIC} onClick={onRequestClose} tooltip="Close" />
      }
      onRequestClose={onRequestClose}
    >
      <div className="invites-content">
        {inviteDirects?.size !== 0 && (
          <div className="invites-content__subheading">
            <Text variant="b3" weight="bold">
              Direct Messages
            </Text>
          </div>
        )}
        {inviteDirects &&
          Array.from(inviteDirects)?.map((roomId) => {
            const mx = initMatrix.matrixClient
            const myRoom = mx.getRoom(roomId)
            if (myRoom === null) return null
            const roomName = myRoom.name
            const inviterId = myRoom.getCreator()
            const inviterName = mx.getUser(inviterId)?.rawDisplayName
            return (
              <RoomTile
                key={myRoom.roomId}
                name={roomName}
                id={myRoom.getDMInviter() || roomId}
                inviterName={inviterName}
                options={
                  procInvite.has(myRoom.roomId) ? (
                    <Spinner size="small" />
                  ) : (
                    <div className="invite-btn__container">
                      <Button onClick={() => rejectInvite(myRoom.roomId, true)}>
                        Reject
                      </Button>
                      <Button
                        onClick={() => acceptInvite(myRoom.roomId, true)}
                        variant="primary"
                      >
                        Accept
                      </Button>
                    </div>
                  )
                }
              />
            )
          })}
        {inviteSpaces?.size !== 0 && (
          <div className="invites-content__subheading">
            <Text variant="b3" weight="bold">
              Spaces
            </Text>
          </div>
        )}
        {inviteSpaces && Array.from(inviteSpaces).map(renderRoomTile)}

        {inviteRooms.size !== 0 && (
          <div className="invites-content__subheading">
            <Text variant="b3" weight="bold">
              Rooms
            </Text>
          </div>
        )}
        {inviteRooms && Array.from(inviteRooms).map(renderRoomTile)}
      </div>
    </PopupWindow>
  )
}

InviteList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired
}

export default InviteList
