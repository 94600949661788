/* eslint-disable no-restricted-syntax */
import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { RootState } from "../../store/store"

interface Profile {
  matrix_config: any
  current_meeting_room_id: string
  dir_anc: string
  dir_list: string
  domain: string
  email: string
  first_name: string
  group: string
  last_name: string
  pk: string
  id?: string
  time_format: string
  time_zone: string
  username: string
  avatar: string
  is_mfa: boolean
  social_calendars: []
  unique_room: boolean
  callid_nmbr: string
  callid_name: string
  callid_emgr: string
  address_id: string
  vmail_enabled: string
  vmail_notify_enabled: string
  vmail_notify: string
  vmail_fwd_to: string
  vmail_greeting: string
  subscriber_pin: string
}

type Value = string | boolean

interface ConfigItem<T> {
  type: string
  value: T
  description: string
}

interface WebinarConfiguration {
  notifications: ConfigItem<string>
  disableProfile: ConfigItem<Value>
  mobileFeatures: ConfigItem<Value>
  toolbarButtons: ConfigItem<string>
  hideAddRoomButton: ConfigItem<Value>
  disableAudioLevels: ConfigItem<Value>
  requireDisplayName: ConfigItem<Value>
  startWithAudioMuted: ConfigItem<Value>
  startWithVideoMuted: ConfigItem<Value>
  disableShowMoreStats: ConfigItem<Value>
  hideParticipantsStats: ConfigItem<Value>
  disableInviteFunctions: ConfigItem<Value>
  disableJoinLeaveSounds: ConfigItem<Value>
  enableNoisyMicDetection: ConfigItem<Value>
}

interface MeetingFeature {
  video_participants: number
  web_participants: number
  custom_meeting_url: number
  international_dial_in_numbers: boolean
  name: string
  recordings: boolean
  config: {}
  webinar_config: Partial<WebinarConfiguration>
}
interface Scope {
  oc_scope: string
  pk: string
  meeting_feature: MeetingFeature
  webinar: boolean
}

type User = {
  is_skip_mfa: boolean
  user: string
  domain: string
  first_name: string
  last_name: string
  group: string
  site: string
  dir: string
  username: string
  is_mfa: boolean
  token: string
  refresh_token: string
}

type MeetingId = {
  is_current: boolean
  label: string
  value: string
}

type MeetingIds = {
  pk: number
  room_id: string
  logo: string
  is_current: boolean
  is_default: boolean
}

type Config = {
  config_name: string
  config_value: string
  domain: string
  description: string
  login_type: string
  reseller: string
  user: string
  role: string
  hostname: string
}[]

interface UsernameState {
  userEmail: string | undefined
  userPassword: string | undefined
  userDetails: User[]
  userName: string | undefined
  userToken: string | undefined
  userMFA: string | undefined
  userProfile: Profile
  userScope: Scope
  statusMessage: string | undefined
  profileImage: string | JSX.Element
  meetingID: MeetingId
  meetingIDs: MeetingIds[]
  is_add_meeting: boolean
  generateAuto: string | undefined
  changedMeetingId: string | undefined
  userConfig: Config
}

const initialState: UsernameState = {
  userEmail: "",
  userPassword: "",
  userDetails: [],
  userName: "",
  userToken: "",
  userMFA: "",
  userProfile: {
    current_meeting_room_id: "",
    dir_anc: "",
    dir_list: "",
    domain: "",
    email: "",
    is_mfa: false,
    first_name: "",
    group: "",
    last_name: "",
    pk: "",
    time_format: "",
    time_zone: "",
    username: "",
    avatar: "",
    social_calendars: [],
    unique_room: false,
    matrix_config: {},
    callid_nmbr: "",
    callid_name: "",
    callid_emgr: "",
    address_id: ""
  },
  userScope: {
    oc_scope: "",
    pk: "",
    webinar: false,
    meeting_feature: {
      video_participants: 0,
      web_participants: 0,
      custom_meeting_url: 0,
      international_dial_in_numbers: false,
      name: "",
      recordings: false,
      config: {},
      webinar_config: {}
    }
  },
  statusMessage: "",
  profileImage: "",
  meetingID: {
    is_current: true,
    label: "",
    value: ""
  },
  meetingIDs: [],
  is_add_meeting: false,
  generateAuto: "",
  changedMeetingId: "",
  userConfig: []
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    saveUserEmail: (state, action: PayloadAction<string | undefined>) => {
      state.userEmail = action.payload
    },
    saveUserProfileEmail: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.userProfile.email = action.payload || ""
    },
    saveUserUniqueRoom: (state, action: PayloadAction<boolean>) => {
      state.userProfile.unique_room = action.payload
    },
    updateUserProfileMFA: (state, action: PayloadAction<boolean>) => {
      state.userProfile.is_mfa = action.payload
    },
    saveUserPassword: (state, action: PayloadAction<string | undefined>) => {
      state.userPassword = action.payload
    },
    saveUsername: (state, action: PayloadAction<string | undefined>) => {
      state.userName = action.payload
    },
    saveUserToken: (state, action: PayloadAction<string | undefined>) => {
      state.userToken = action.payload
    },
    saveUserMFA: (state, action: PayloadAction<string | undefined>) => {
      state.userMFA = action.payload
    },
    saveUserData: (state, action: PayloadAction<User[]>) => {
      state.userDetails = action.payload
    },
    saveUserProfile: (state, action: PayloadAction<Profile>) => {
      state.userProfile = action.payload
    },
    saveUserScope: (state, action: PayloadAction<Scope>) => {
      state.userScope = action.payload
    },
    saveUserImages: (state, action: PayloadAction<string | JSX.Element>) => {
      state.profileImage = action.payload
    },
    saveUserStatusMessage: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.statusMessage = action.payload
    },
    saveUserCurrentMeetinId: (state, action: PayloadAction<MeetingId>) => {
      state.meetingID = action.payload
    },
    saveUserMeetingIds: (state, action: PayloadAction<MeetingIds[]>) => {
      state.meetingIDs = action.payload
    },
    isAddMeeting: (state, action: PayloadAction<boolean>) => {
      state.is_add_meeting = action.payload
    },
    generateAuto: (state, action: PayloadAction<string | undefined>) => {
      state.generateAuto = action.payload
    },
    changedMeetingIds: (state, action: PayloadAction<string>) => {
      state.changedMeetingId = action.payload
    },
    currentMeetingId: (state, action: PayloadAction<string>) => {
      state.userProfile.current_meeting_room_id = action.payload
    },
    saveUserConfig: (state, action: PayloadAction<Config>) => {
      state.userConfig = action.payload
    },
    resetProfile: () => initialState
  }
})

export const {
  saveUserEmail,
  saveUserProfileEmail,
  saveUserUniqueRoom,
  saveUserPassword,
  saveUserData,
  saveUsername,
  saveUserToken,
  saveUserMFA,
  saveUserProfile,
  saveUserScope,
  saveUserImages,
  saveUserStatusMessage,
  saveUserCurrentMeetinId,
  saveUserMeetingIds,
  isAddMeeting,
  generateAuto,
  changedMeetingIds,
  updateUserProfileMFA,
  currentMeetingId,
  saveUserConfig,
  resetProfile
} = authSlice.actions

export const selectData = (state: RootState) => state.auth.userPassword

export default authSlice.reducer
