/* eslint-disable import/prefer-default-export */
import { PrepareAction, createAction, createAsyncThunk } from "@reduxjs/toolkit"

import { AppState, Call, WebPhoneState } from "./type"
import { generateCallId, getAttendee, getCurrentCall, getNextCall, sleep } from "../utils/call"
import { WebPhoneAPI } from "../type"
import { getCallRecordingAPI, getConferenceBridgeAPI } from "./webPhoneActions"

declare const window: WebPhoneAPI

interface StoreState {
  webPhone: WebPhoneState
}

export const WebPhoneActionAPI = {
  APP_STATE: "APP_STATE",
  DIAL_CALL: "DIAL_CALL",
  ADD_CALL: "ADD_CALL",
  ACCEPT_CALL: "ACCEPT_CALL",
  END_CALL: "END_CALL",
  IGNORE_CALL: "IGNORE_CALL",
  REJECT_CALL: "REJECT_CALL",
  MUTE_CALL: "MUTE_CALL",
  HOLD_CALL: "HOLD_CALL",
  SWAP_CALL: "SWAP_CALL",
  MERGE_CALL: "MERGE_CALL",
  SHOW_CONTACTS: "SHOW_CONTACTS",
  TRANSFER_CALL: "TRANSFER_CALL",
  COMPLETE_TRANSFER_CALL: "COMPLETE_TRANSFER_CALL",
  CANCEL_TRANSFER_CALL: "CANCEL_TRANSFER_CALL",
  TRANSFER_CALL_TOGGLE: "TRANSFER_CALL_TOGGLE",
  PICK_NEXT_CALL: "PICK_NEXT_CALL",
  PICK_HOLD_CALL: "PICK_HOLD_CALL",
  OUTGOING_RINGING_CALL: "OUTGOING_RINGING_CALL",
  OUTGOING_TRYING_CALL: "OUTGOING_TRYING_CALL",
  INCOMING_RINGING_CALL: "INCOMING_RINGING_CALL",
  CALL_CONNECTED_LISTENER: "CALL_CONNECTED_LISTENER",
  SHOW_ADD_CALL_OPTION: "SHOW_ADD_CALL_OPTIONS",
  SHOW_DIAL_PAD_ON_CALL: "SHOW_DIAL_PAD_ON_CALL",
  DISABLE_CONTROLS: "DISABLE_CONTROLS",
  CALL_DISCONNECTED_LISTENER: "CALL_DISCONNECTED_LISTENER",
  SEND_DIAL_NUMBER: "SEND_DIAL_NUMBER"
}

export const slicePrefix = (value: string) => `webPhone/${value}`

export const appStateChangeAPI = createAction<PrepareAction<AppState>>(
  slicePrefix(WebPhoneActionAPI.APP_STATE),
  (payload) => {
    // if webphone `AppStateChanges` return failed status
    // then we stop webphone engine and hangup all line
    if (payload === AppState.failed) {
      window.webphone_api.setline(-2)
      window.webphone_api.hangup()
      window.webphone_api.stop()
    }

    return { payload }
  }
)

type DialCallAPI = { extension: string; callID?: string }
export const dialCallAPI = createAction<PrepareAction<DialCallAPI>>(
  slicePrefix(WebPhoneActionAPI.DIAL_CALL),
  (payload: DialCallAPI) => {
    const callID = window.webphone_api.nextcallid(generateCallId())

    window.webphone_api.call(payload.extension)
    return { payload: { ...payload, callID: `[${callID}]` } }
  }
)

type AddCallAPI = { extension: string; callID?: string }
export const addCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.ADD_CALL),
  async (payload: AddCallAPI, { getState }) => {
    const { currentCallId, calls } = (getState() as StoreState).webPhone
    const currentCall = getCurrentCall(currentCallId, calls)

    const attendee = getAttendee(currentCall?.attendees ?? {})?.extension ?? ""

    let currentCallRecordingAction = "record_off"

    if (
      currentCall?.isRecording?.action !== "record_off" &&
      currentCall?.isRecording?.action !== "record_pause"
    ) {
      currentCallRecordingAction = "record_pause"
      getCallRecordingAPI("record_pause", currentCallId)
    }

    const callID = window.webphone_api.nextcallid(generateCallId())

    // const action = currentCall?.isIncomingCaller ? "hold_term" : "hold_orig"
    // holdCallAPI(action, currentCallId)

    window.webphone_api.setline(attendee)
    window.webphone_api.hold(true)

    sleep()

    window.webphone_api.nextcallid(callID)
    window.webphone_api.call(payload.extension)

    return { ...payload, callID: `[${callID}]`, currentCallRecordingAction }
  }
)

type OnCallTryingListenerAPI = { extension: string; callID: string }
export const onCallTryingListenerAPI = createAction<PrepareAction<OnCallTryingListenerAPI>>(
  slicePrefix(WebPhoneActionAPI.OUTGOING_TRYING_CALL),
  (payload) => ({ payload })
)

type OnOutgoingCallRingingAPI = { extension: string; callID: string }
export const onOutgoingCallRingingAPI = createAction<PrepareAction<OnOutgoingCallRingingAPI>>(
  slicePrefix(WebPhoneActionAPI.OUTGOING_RINGING_CALL),
  (payload: OnOutgoingCallRingingAPI) => ({ payload })
)

type OnIncomingCallRingingAPI = { extension: string; callID: string; callerName: string }
export const onIncomingCallRingingAPI = createAction<PrepareAction<OnIncomingCallRingingAPI>>(
  slicePrefix(WebPhoneActionAPI.INCOMING_RINGING_CALL),
  (payload: OnIncomingCallRingingAPI) => {
    return { payload: { ...payload } }
  }
)

type AcceptIncomingCallAPI = { extension: string; callID: string }
export const acceptIncomingCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.ACCEPT_CALL),
  async (payload: AcceptIncomingCallAPI, { getState }) => {
    const { currentCallId, calls } = (getState() as StoreState).webPhone
    const currentCall = getCurrentCall(currentCallId, calls)
    const attendee = getAttendee(currentCall?.attendees ?? {})?.extension ?? ""
    let currentCallRecordingAction = "record_off"

    if (currentCallId) {
      if (
        currentCall?.isRecording?.action !== "record_off" &&
        currentCall?.isRecording?.action !== "record_pause"
      ) {
        currentCallRecordingAction = "record_pause"
        getCallRecordingAPI("record_pause", currentCallId)
      }
      // window.webphone_api.setline for attendee(extension) or -2(for all lines)
      // const action = currentCall?.isIncomingCaller ? "hold_term" : "hold_orig"
      // holdCallAPI(action, currentCallId)

      window.webphone_api.setline(attendee || -2)
      window.webphone_api.hold(true)
      sleep()
    }
    window.webphone_api.setline(payload.extension || payload.callID)
    window.webphone_api.accept()

    window.webphone_api.hold(false)
    return { ...payload, currentCallRecordingAction }
  }
)

type OnCallConnectedListenerAPI = { extension: string; callID: string }
export const onCallConnectedListenerAPI = createAction<PrepareAction<OnCallConnectedListenerAPI>>(
  slicePrefix(WebPhoneActionAPI.CALL_CONNECTED_LISTENER),
  (payload) => ({ payload })
)

type OnCallDisconnectListenerAPI = { extension: string; callID: string }
export const onCallDisconnectListenerAsyncAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.CALL_DISCONNECTED_LISTENER),
  async (payload: OnCallDisconnectListenerAPI) => {
    return payload
  }
)

export const MuteCallAPI = createAction<PrepareAction<boolean | undefined>>(
  slicePrefix(WebPhoneActionAPI.MUTE_CALL),
  (payload) => {
    const isMute = payload ?? !window.webphone_api.ismuted()
    window.webphone_api.mute(isMute, 2)
    return { payload: isMute }
  }
)

export const HoldCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.HOLD_CALL),
  async (payload: boolean | undefined, { getState }) => {
    const { currentCallId, calls, conferenceBridge } = (getState() as StoreState).webPhone
    const currentCall = getCurrentCall(currentCallId, calls)
    // const inOnHold = payload

    const inOnHold = payload ?? !window.webphone_api.isonhold()

    let recordingAction = "record_off"
    if (
      inOnHold &&
      currentCall?.isRecording?.action !== "record_off" &&
      currentCall?.isRecording?.action !== "record_pause"
    ) {
      recordingAction = "record_pause"
      getCallRecordingAPI("record_pause", currentCallId)
    } else if (!inOnHold && currentCall?.isRecording?.action === "record_pause") {
      recordingAction = "record_unpause"
      getCallRecordingAPI("record_unpause", currentCallId)
    } else {
      recordingAction = "record_off"
    }
    // const action = currentCall?.isOnHold
    //   ? "unhold"
    //   : currentCall?.isIncomingCaller
    //   ? "hold_term"
    //   : "hold_orig"
    // holdCallAPI(action, currentCallId)

    window.webphone_api.hold(inOnHold)

    return { inOnHold, recordingAction }
  }
)

export const showContactsOptionAPI = createAction(slicePrefix(WebPhoneActionAPI.SHOW_CONTACTS))

export type MergeCallAPI = Partial<{ mergeCallID: string[]; callID: string } | undefined>
export const mergeCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.MERGE_CALL),
  async (payload: MergeCallAPI, { getState }) => {
    const { calls } = (getState() as StoreState).webPhone
    const callID = window.webphone_api.nextcallid(generateCallId())

    let error = false

    let callIds: any = calls.map((call) => {
      let callId = call.callID?.replace(/\[|\]/g, "")
      if (call.isIncomingCaller) {
        callId += "xfer_orig"
      } else {
        callId += "xfer_term"
      }

      return callId
    })

    callIds = callIds?.join(",")

    const conferenceBridge: string = await getConferenceBridgeAPI(callIds)

    if (conferenceBridge) {
      window.webphone_api.call(conferenceBridge)
    } else {
      error = true
    }

    return {
      error,
      callID,
      conferenceBridge
    }
  }
)

export type TransferCallOptionAPI = { contactFrom: "dial" | "contact" }
export const transferCallOptionAPI = createAction<PrepareAction<TransferCallOptionAPI>>(
  slicePrefix(WebPhoneActionAPI.TRANSFER_CALL_TOGGLE),
  (payload) => ({ payload })
)

export type TransferCallAPI = { extension: string; transferType: 1 | 5; callID?: string }
export const transferCallAPI = createAction<PrepareAction<TransferCallAPI>>(
  slicePrefix(WebPhoneActionAPI.TRANSFER_CALL),
  (payload: TransferCallAPI) => {
    const callID = window.webphone_api.nextcallid(generateCallId())

    window.webphone_api.setparameter("transfertype", payload.transferType)
    window.webphone_api.transfer(payload.extension)
    return { payload: { ...payload, callID: `[${callID}]` } }
  }
)

export type CompleteTransferCallAPI = { extension: string }
export const completeTransferCallAPI = createAction<PrepareAction<CompleteTransferCallAPI>>(
  slicePrefix(WebPhoneActionAPI.COMPLETE_TRANSFER_CALL),
  (payload: CompleteTransferCallAPI) => {
    // const { currentCallId, calls } = (getState() as StoreState).webPhone
    // const currentCall = getCurrentCall(currentCallId, calls)
    window.webphone_api.setparameter("transfertype", 7)
    window.webphone_api.setparameter("transfwithreplace", 2)
    window.webphone_api.setparameter("defmute", 5)
    // holdCallAPI("unhold", currentCallId)
    window.webphone_api.hold(false)
    window.webphone_api.transfer(payload.extension)

    sleep()

    window.webphone_api.setparameter("transfertype", "")
    window.webphone_api.setparameter("transfwithreplace", "")
    window.webphone_api.setparameter("defmute", "")
    return { payload }
  }
)

export type CancelTransferCallAPI = { extension: string }
export const cancelTransferCallAPI = createAction<PrepareAction<CancelTransferCallAPI>>(
  slicePrefix(WebPhoneActionAPI.CANCEL_TRANSFER_CALL),
  (payload: CancelTransferCallAPI) => {
    window.webphone_api.setline(payload.extension)
    window.webphone_api.hangup()
    return { payload }
  }
)

export type SwapCallAPI = Partial<{ nextCallID: Call; previousCallID: string }>
export const swapCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.SWAP_CALL),
  async (payload: Partial<SwapCallAPI> | undefined, { getState }) => {
    const { calls, currentCallId } = (getState() as StoreState).webPhone
    const currentCall = getCurrentCall(currentCallId, calls)
    let nextCallRecordingAction = "record_off"
    let previousCallRecordingAction = "record_off"

    if (currentCallId) {
      if (
        currentCall?.isRecording?.action !== "record_off" &&
        currentCall?.isRecording?.action !== "record_pause"
      ) {
        previousCallRecordingAction = "record_pause"
        getCallRecordingAPI("record_pause", currentCallId)
      }

      const nextCall = getNextCall(currentCallId, calls)
      const attendee = getAttendee(nextCall?.attendees ?? {})?.extension ?? ""

      // const action = currentCall?.isIncomingCaller ? "hold_term" : "hold_orig"
      // holdCallAPI(action, currentCallId)

      window.webphone_api.hold(true)
      sleep()

      if (
        nextCall?.isRecording?.action !== "record_off" &&
        nextCall?.isRecording?.action === "record_pause"
      ) {
        nextCallRecordingAction = "record_unpause"
        getCallRecordingAPI("record_unpause", nextCall?.callID)
      }

      window.webphone_api.setline(attendee)
      // holdCallAPI("unhold", nextCall?.callID)
      window.webphone_api.hold(false)

      return {
        nextCallID: nextCall?.callID,
        previousCallID: currentCallId,
        nextCallRecordingAction,
        previousCallRecordingAction
      }
    }

    return {}
  }
)

export const pickNextCallAsyncAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.PICK_NEXT_CALL),
  (payload, { getState }) => {
    const { calls, currentCallId } = (getState() as StoreState).webPhone

    if (!currentCallId) {
      const onHoldCall = calls.filter((caller) => caller.isOnHold)
      const lastCaller = onHoldCall.length ? onHoldCall[0] : null
      let lastCallerRecordingAction = "record_off"

      if (lastCaller) {
        if (
          lastCaller?.isRecording?.action !== "record_off" &&
          lastCaller?.isRecording?.action === "record_pause"
        ) {
          lastCallerRecordingAction = "record_unpause"
          getCallRecordingAPI("record_unpause", lastCaller?.callID)
        }
        const extension = getAttendee(lastCaller.attendees)
        window.webphone_api.setline(extension?.extension ?? "")
        // holdCallAPI("unhold", lastCaller.callID)
        window.webphone_api.hold(false)
      }
      return { lastCallerCallId: lastCaller?.callID, lastCallerRecordingAction }
    }

    return {}
  }
)

type PickHoldCallAPI = {
  currentCallExtension: string
  extension: string
  callID: string
}
export const pickHoldCallAPI = createAsyncThunk(
  slicePrefix(WebPhoneActionAPI.PICK_HOLD_CALL),
  async (payload: PickHoldCallAPI, { getState }) => {
    // stop recording of current call if recording is on
    const { calls, currentCallId } = (getState() as StoreState).webPhone
    const currentCall = getCurrentCall(currentCallId, calls)
    const pickedHoldCall = getCurrentCall(payload.callID, calls)

    let currentCallRecordingAction = "record_off"
    let pickedHoldCallRecordingAction = "record_off"

    if (currentCallId) {
      if (
        currentCall?.isRecording?.action !== "record_off" &&
        currentCall?.isRecording?.action !== "record_pause"
      ) {
        currentCallRecordingAction = "record_pause"
        getCallRecordingAPI("record_pause", currentCallId)
      }
    }

    // put current call on hold by id
    window.webphone_api.setline(payload.currentCallExtension)
    // const action = currentCall?.isIncomingCaller ? "hold_term" : "hold_orig"
    // holdCallAPI(action, currentCallId)

    window.webphone_api.hold(true)

    // check pickedCaller is having recording paused or off ?
    if (payload.callID) {
      if (
        pickedHoldCall?.isRecording?.action !== "record_off" &&
        pickedHoldCall?.isRecording?.action === "record_pause"
      ) {
        pickedHoldCallRecordingAction = "record_unpause"
        getCallRecordingAPI("record_unpause", payload.callID)
      }
    }

    // pick hold call and resume call
    window.webphone_api.setline(payload.extension)
    // holdCallAPI("unhold", currentCallId)
    window.webphone_api.hold(false)
    return { ...payload, currentCallRecordingAction, pickedHoldCallRecordingAction }
  }
)

type IgnoreCallAPI = { extension: string; callID: string }
export const ignoreCallAPI = createAction<PrepareAction<IgnoreCallAPI>>(
  slicePrefix(WebPhoneActionAPI.IGNORE_CALL),
  (payload) => {
    window.webphone_api.setline(payload.extension)
    window.webphone_api.ignore()
    return { payload }
  }
)

type RejectCallAPI = { extension: string; callID: string }
export const rejectCallAPI = createAction<PrepareAction<RejectCallAPI>>(
  slicePrefix(WebPhoneActionAPI.REJECT_CALL),
  (payload) => {
    window.webphone_api.setline(payload.extension)
    window.webphone_api.reject()
    return { payload }
  }
)

type EndCallAPI = { extension: string; callID: string }
export const endCallAPI = createAction<PrepareAction<EndCallAPI>>(
  slicePrefix(WebPhoneActionAPI.END_CALL),
  (payload: EndCallAPI) => {
    window.webphone_api.setline(payload.extension || payload.callID)
    window.webphone_api.hangup()
    return { payload }
  }
)

export const ShowAddCallOptionAPI = createAction(
  slicePrefix(WebPhoneActionAPI.SHOW_ADD_CALL_OPTION),
  () => ({ payload: {} })
)

export const ShowDialPadOnCallAPI = createAction(
  slicePrefix(WebPhoneActionAPI.SHOW_DIAL_PAD_ON_CALL),
  () => {
    return { payload: {} }
  }
)

export const SendDialNumberCallAPI = createAction<PrepareAction<string>>(
  slicePrefix(WebPhoneActionAPI.SEND_DIAL_NUMBER),
  (payload) => {
    window.webphone_api.dtmf(payload)
    return { payload }
  }
)

export const resetCallControlsAPI = createAction<PrepareAction<boolean>>(
  slicePrefix(WebPhoneActionAPI.DISABLE_CONTROLS),
  (payload) => {
    return { payload }
  }
)
