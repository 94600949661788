import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { message } from "antd"
import {
  getCallHistory,
  getCallRecording,
  getContactLists,
  getDispositionOption,
  getWebPhoneCredential,
  showMessageOnCallChange
} from "./webPhoneActions"
import { AppState, CallState, WebPhoneState } from "./type"
import {
  acceptIncomingCallAPI,
  onCallConnectedListenerAPI,
  appStateChangeAPI,
  dialCallAPI,
  onIncomingCallRingingAPI,
  onOutgoingCallRingingAPI,
  rejectCallAPI,
  onCallDisconnectListenerAsyncAPI,
  HoldCallAPI,
  MuteCallAPI,
  addCallAPI,
  ShowAddCallOptionAPI,
  endCallAPI,
  pickNextCallAsyncAPI,
  ShowDialPadOnCallAPI,
  resetCallControlsAPI,
  onCallTryingListenerAPI,
  pickHoldCallAPI,
  transferCallOptionAPI,
  swapCallAPI,
  transferCallAPI,
  showContactsOptionAPI,
  mergeCallAPI
} from "./webPhoneAPI"
import {
  callDto,
  getAttendee,
  getAttendeesWithCall,
  getCallIndexById,
  removeAttendeeFromCall,
  removeCallFromCalls
} from "../utils/call"

// Define the initial state using that type
const initialState: WebPhoneState = {
  appState: AppState.unregistered,
  calls: [],
  open: false,
  callState: "dial",
  showCallEnded: false,
  currentCallId: undefined,
  // resetPreviousSessionModal: false,
  credentials: {
    proxy: "",
    domain: "",
    password: "",
    username: "",
    extension: "",
    is_registered: false
  },
  contacts: { lists: [], errorMessage: "", isLoading: true, isSuccess: false },
  callHistory: {
    lists: [],
    errorMessage: "",
    isLoading: true,
    isSuccess: false
  },
  previousDialpadBtnClicked: 0,
  inboundDispositionOptions: [],
  outboundDispositionOptions: []
  // callCenterStatus: "Other"
}

const webPhoneSlice = createSlice({
  name: "webPhoneSlice",
  initialState,
  reducers: {
    toggleDialer: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload
    },
    toggleCallState: (state, { payload }: PayloadAction<CallState>) => {
      state.callState = payload
    },
    setDialpadBtnClickTime: (state, { payload }: PayloadAction<number>) => {
      state.previousDialpadBtnClicked = payload
    },
    // setCallCenterStatus: (state, { payload }: PayloadAction<callCenterType>) => {
    //   state.callCenterStatus = payload
    // },
    resetWebphone: () => initialState
    // resetPreviousSessionModalState: (state, { payload }: PayloadAction<boolean>) => {
    //   state.resetPreviousSessionModal = payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(appStateChangeAPI, (state, { payload }) => {
        state.appState = payload

        // if AppState value is failed/unRegistered
        // then clean all the calls and reset webphone state
        if (payload !== AppState.registered) {
          state.open = false

          state.calls = []
          state.callState = "dial"
          state.currentCallId = ""
        }
      })
      .addCase(dialCallAPI, (state, { payload }) => {
        state.open = true
        state.callState = "call"
        state.currentCallId = payload.callID

        if (payload.callID) {
          const call = callDto(payload.extension, payload.callID, state.contacts.lists)
          state.calls.push(call)
        }
      })
      .addCase(addCallAPI.fulfilled, (state, { payload }) => {
        const { callID, extension, currentCallRecordingAction } = payload
        const callIndex = getCallIndexById(state.currentCallId, state.calls)

        if (state.currentCallId && callIndex >= 0) {
          state.calls[callIndex].isOnHold = true
          state.calls[callIndex].isRecording = {
            action: currentCallRecordingAction
          }
          state.calls[callIndex].isMute = false
          state.calls[callIndex].showDialPad = false
          state.calls[callIndex].isIncomingCall = false
          state.calls[callIndex].isTransferCall = false
          state.calls[callIndex].isAddCallOption = false
        }

        if (callID) {
          state.open = true
          state.callState = "call"
          state.currentCallId = callID
          state.calls.push(callDto(extension, callID, state.contacts.lists))
        }
      })
      .addCase(swapCallAPI.fulfilled, (state, { payload }) => {
        const { calls } = state
        const { nextCallID, previousCallID, previousCallRecordingAction, nextCallRecordingAction } =
          payload

        const nextCallIndex = getCallIndexById(nextCallID, calls)
        const previousCallIndex = getCallIndexById(previousCallID, calls)

        if (previousCallIndex >= 0) {
          state.calls[previousCallIndex].isOnHold = true
          state.calls[previousCallIndex].isRecording = {
            action: previousCallRecordingAction || "record_off"
          }
        }

        if (nextCallIndex >= 0) {
          state.currentCallId = nextCallID
          state.calls[nextCallIndex].isOnHold = false
          state.calls[nextCallIndex].isRecording = {
            action: nextCallRecordingAction || "record_off"
          }
        }
      })
      .addCase(onCallTryingListenerAPI, (state, { payload }) => {
        const callByIdIndex = getCallIndexById(payload.callID, state.calls)

        if (callByIdIndex >= 0) {
          state.calls[callByIdIndex].callID = payload.callID
          state.calls[callByIdIndex].attendees[payload.extension].event = "connecting"
        }
      })
      .addCase(onOutgoingCallRingingAPI, (state, { payload }) => {
        const callIndex = getCallIndexById(payload.callID, state.calls)

        if (callIndex >= 0) {
          state.currentCallId = payload.callID
          state.calls[callIndex].callID = payload.callID
          state.calls[callIndex].attendees[payload.extension].event = "calling"
        }
      })
      .addCase(onIncomingCallRingingAPI, (state, { payload }) => {
        // Add Caller ID name into the attendees
        const { callID, extension, callerName } = payload
        const call = callDto(extension, callID, state.contacts.lists)

        if (
          callerName &&
          (call.attendees[extension].user?.first_name === "Unknown" ||
            call.attendees[extension].user?.last_name === "Unknown" ||
            (!call.attendees[extension].user?.first_name &&
              !call.attendees[extension].user?.last_name))
        ) {
          const [firstName, lastName]: any = callerName?.split(" ")
          call.attendees[extension].user.first_name = firstName
          call.attendees[extension].user.last_name = lastName
        }

        call.isIncomingCall = true
        call.isIncomingCaller = true
        call.attendees[extension].event = "ringing"

        state.calls = removeCallFromCalls(callID, state.calls)
        state.calls.push(call)
      })
      .addCase(onCallConnectedListenerAPI, (state, { payload }) => {
        const callIndex = getCallIndexById(payload.callID, state.calls)

        if (callIndex >= 0) {
          const call = state.calls[callIndex].attendees[payload.extension]

          call.event = "connected"
          call.startTime = +new Date()
        }
      })
      .addCase(onCallDisconnectListenerAsyncAPI.pending, () => {})
      .addCase(onCallDisconnectListenerAsyncAPI.fulfilled, (state, { payload }) => {
        const callIndex = getCallIndexById(payload.callID, state.calls)

        if (callIndex >= 0) {
          if (state.calls[callIndex].callType === "conference") {
            state.conferenceBridge = ""
          }
          state.calls[callIndex] = removeAttendeeFromCall(payload.extension, state.calls[callIndex])
        }

        state.calls = getAttendeesWithCall(state.calls)

        const isCurrentCallExist = getCallIndexById(state.currentCallId, state.calls)

        if (isCurrentCallExist < 0) {
          state.open = false

          state.callState = "dial"
          state.currentCallId = ""
        }
      })
      .addCase(acceptIncomingCallAPI.fulfilled, (state, { payload }) => {
        const { currentCallId, calls } = state
        const currentCallIndex = getCallIndexById(currentCallId, calls)
        const callIndex = getCallIndexById(payload.callID, state.calls)

        if (currentCallIndex >= 0) {
          state.calls[currentCallIndex].isOnHold = true
          state.calls[currentCallIndex].isRecording = {
            action: payload.currentCallRecordingAction
          }
          state.currentCallId = ""
        }

        if (callIndex >= 0) {
          state.open = true
          state.callState = "call"
          state.currentCallId = payload.callID
          state.calls[callIndex].isMute = false
          state.calls[callIndex].isOnHold = false
          state.calls[callIndex].isRecording = {
            action: "record_off"
          }
          state.calls[callIndex].showDialPad = false
          state.calls[callIndex].isTransferCall = false
          state.calls[callIndex].isIncomingCall = false
          state.calls[callIndex].isIncomingCaller = true
          state.calls[callIndex].isAddCallOption = false
        }
      })

      .addCase(endCallAPI, (state, { payload }) => {
        const callIndex = getCallIndexById(payload.callID, state.calls)
        const isConferenceCall = state.calls[callIndex]?.callType === "conference"

        state.showCallEnded = false
        if (isConferenceCall) {
          state.calls = removeCallFromCalls(payload.callID, state.calls)
        }

        if (!isConferenceCall && callIndex >= 0) {
          state.showCallEnded = false
          state.calls[callIndex] = removeAttendeeFromCall(payload.extension, state.calls[callIndex])
        }

        state.calls = getAttendeesWithCall(state.calls)

        const isCurrentCallExist = getCallIndexById(state.currentCallId, state.calls)

        if (isCurrentCallExist >= 0) {
          state.open = false

          state.callState = "dial"
          state.currentCallId = undefined
        }
      })
      .addCase(rejectCallAPI, (state, { payload }) => {
        state.calls = removeCallFromCalls(payload.callID, state.calls)
      })
      .addCase(transferCallAPI, (state, { payload }) => {
        const { extension, callID } = payload

        // blind transfer
        if (callID && payload.transferType === 1) {
          state.open = false

          state.callState = "dial"
        }

        // assists transfer
        if (callID && payload.transferType === 5) {
          const call = callDto(extension, callID, state.contacts.lists)
          const callIndex = getCallIndexById(state.currentCallId, state.calls)

          state.callState = "call"
          state.currentCallId = callID
          state.calls[callIndex].isOnHold = true
          state.calls[callIndex].isRecording = {
            action:
              state.calls[callIndex].isRecording?.action !== "record_off"
                ? "record_pause"
                : "record_off"
          }

          state.calls.push({ ...call, callType: "transfer" })
        }
      })
      .addCase(pickNextCallAsyncAPI.fulfilled, (state, { payload }) => {
        const callIndex = getCallIndexById(payload.lastCallerCallId, state.calls)

        if (callIndex >= 0) {
          state.open = true
          state.callState = "call"
          state.currentCallId = payload.lastCallerCallId
          state.calls[callIndex].isOnHold = false
          state.calls[callIndex].isRecording = {
            action: payload.lastCallerRecordingAction || "record_off"
          }
        }
      })
      // -------------------------------------------
      // ------------- Call Controls ---------------
      // -------------------------------------------
      .addCase(showContactsOptionAPI, (state) => {
        state.callState = "contacts"
      })
      .addCase(pickHoldCallAPI.fulfilled, (state, { payload }) => {
        const callIndex = getCallIndexById(state.currentCallId, state.calls)

        if (callIndex >= 0) {
          state.calls[callIndex].isOnHold = true
          state.calls[callIndex].isRecording = {
            action: payload.currentCallRecordingAction
          }
        }

        const holdCallIndex = getCallIndexById(payload.callID, state.calls)

        if (holdCallIndex >= 0) {
          state.currentCallId = state.calls[holdCallIndex].callID
          state.calls[holdCallIndex].isOnHold = false
          state.calls[holdCallIndex].isRecording = {
            action: payload.pickedHoldCallRecordingAction
          }
        }
      })
      .addCase(HoldCallAPI.fulfilled, (state, { payload }) => {
        const callIndex = getCallIndexById(state.currentCallId, state.calls)

        if (callIndex >= 0) {
          state.calls[callIndex].isOnHold = payload.inOnHold
          state.calls[callIndex].isRecording = {
            action: payload.recordingAction
          }
        }
      })
      .addCase(MuteCallAPI, (state, { payload }) => {
        const callIndex = getCallIndexById(state.currentCallId, state.calls)

        if (callIndex >= 0) {
          state.calls[callIndex].isMute = payload
        }
      })
      .addCase(ShowAddCallOptionAPI, (state) => {
        if (state?.currentCallId) {
          const callIndex = getCallIndexById(state.currentCallId, state.calls)

          const isOpen = !state.calls[callIndex].isAddCallOption

          state.callState = isOpen ? "dial" : "call"
          state.calls[callIndex].isAddCallOption = isOpen
        }
      })
      .addCase(ShowDialPadOnCallAPI, (state) => {
        if (state?.currentCallId) {
          const callIndex = getCallIndexById(state.currentCallId, state.calls)
          const isOpen = !state.calls[callIndex].showDialPad
          state.calls[callIndex].showDialPad = isOpen
        }
      })
      .addCase(transferCallOptionAPI, (state, { payload }) => {
        if (state?.currentCallId) {
          const callIndex = getCallIndexById(state.currentCallId, state.calls)

          const isOpen = !state.calls[callIndex].showDialPad

          state.open = true
          state.calls[callIndex].isTransferCall = isOpen
          state.callState = payload.contactFrom === "dial" ? "dial" : "contacts"
        }
      })
      .addCase(mergeCallAPI.fulfilled, (state, { payload }) => {
        if (!payload.error) {
          state.showCallEnded = true
          const confCall = callDto("", payload.callID, state.contacts.lists)
          confCall.callType = "conference"

          state.calls.forEach((call) => {
            const attendee = getAttendee(call.attendees)

            if (attendee?.extension) {
              confCall.attendees[attendee.extension] = attendee
            }
          })

          state.calls = [confCall]

          state.currentCallId = payload.callID
          state.conferenceBridge = payload.conferenceBridge
        }
      })
      .addCase(showMessageOnCallChange.fulfilled, (state, { payload }) => {
        switch (payload) {
          case "User hangup":
          case "Call Rejected":
          case "Call terminated":
          case "Disconnected by Caller":
            if (!state.showCallEnded) {
              message.info("Call Ended")
            }
            break
          default:
            break
        }
      })
      .addCase(resetCallControlsAPI, (state) => {
        if (state?.currentCallId) {
          const callIndex = getCallIndexById(state.currentCallId, state.calls)

          state.calls[callIndex].showDialPad = false
          state.calls[callIndex].isTransferCall = false
          state.calls[callIndex].isAddCallOption = false
        }
      })
      // -------------------------------------------
      // --------- API Response Call ---------------
      // -------------------------------------------
      .addCase(getContactLists.pending, (state) => {
        state.contacts.isLoading = true
        state.contacts.errorMessage = ""
      })
      .addCase(getContactLists.fulfilled, (state, action) => {
        state.contacts.isLoading = false
        state.contacts.lists = action.payload
      })
      .addCase(getContactLists.rejected, (state, action) => {
        state.contacts.isLoading = false
        state.contacts.errorMessage = action.error.message || "Something went wrong"
      })
      .addCase(getCallHistory.pending, (state) => {
        state.callHistory.isLoading = true
        state.callHistory.errorMessage = ""
      })
      .addCase(getCallHistory.fulfilled, (state, action) => {
        state.callHistory.isLoading = false
        state.callHistory.lists = action.payload
      })
      .addCase(getCallHistory.rejected, (state, action) => {
        state.callHistory.isLoading = false
        state.callHistory.errorMessage = action.error.message || "Something went wrong"
      })
      .addCase(getWebPhoneCredential.fulfilled, (state, action) => {
        state.credentials = action.payload
        state.credentials.loading = false
        state.credentials.error = false
        state.credentials.is_registered = action.payload.is_registered
      })
      .addCase(getWebPhoneCredential.rejected, (state) => {
        state.credentials.loading = false
        state.credentials.error = true
      })
      .addCase(getCallRecording.pending, (state, action) => {
        // loading..
      })
      .addCase(getCallRecording.fulfilled, (state, { payload }) => {
        // change the recording state,
        const callIndex = getCallIndexById(payload.callID, state.calls)

        if (callIndex >= 0) {
          const call = state.calls[callIndex]
          call.isRecording = {
            action: payload.action
          }
        }
      })
      .addCase(getCallRecording.rejected, (state, action) => {
        // throw error on reject
      })
      .addCase(getDispositionOption.fulfilled, (state, { payload }) => {
        if (payload.boundType === "Inbound") {
          state.inboundDispositionOptions = payload.data
        } else {
          state.outboundDispositionOptions = payload.data
        }
      })
  }
})

export const {
  toggleDialer,
  toggleCallState,
  setDialpadBtnClickTime,
  // setCallCenterStatus,
  resetWebphone
  // resetPreviousSessionModalState
} = webPhoneSlice.actions

const webPhoneReducer = webPhoneSlice.reducer
export default webPhoneReducer
