export type CallEvent =
  | "ringing"
  | "calling"
  | "connected"
  | "connecting"
  | "hold"
export type CallState = "dial" | "call" | "contacts"
export type CallType = "1:1" | "transfer" | "conference"

export interface CallDetails {
  order: number
  extension: string
  startTime?: number
  event: CallEvent
  user: Contact
  duration?: { hours: number; minutes: number; seconds: number }
}

export interface Contact {
  avatar: string
  domain: string
  email: string
  first_name: string
  id: number
  last_name: string
  last_update: string
  matrix_user_id: string
  scope: string
  user: string
  flag?: 1 | 2
  work_phone?: string
  cell_phone?: string
  home_phone?: string
}

export interface RecordState {
  action: string
}

export type AttendeesCall = Record<string, CallDetails>

export type CallControl = {
  callID?: string
  isMute?: boolean
  isOnHold?: boolean
  showDialPad?: boolean
  isAddCallOption?: boolean
  isIncomingCall?: boolean
  isIncomingCaller?: boolean
  isTransferCall?: boolean
  isRecording?: RecordState
  callType: CallType
}

export type Call = CallControl & { attendees: AttendeesCall }

export enum AppState {
  "registered" = "registered",
  "failed" = "failed",
  "unregistered" = "unregistered"
}

interface ContactEntity {
  lists: Contact[]
  isLoading: boolean
  isSuccess: boolean
  errorMessage: string
}

interface ConferenceBridge {
  aor: string
  leader_required: string
  name: string
  quorum: string
  username: string
}

interface Credentials {
  loading?: boolean
  error?: boolean
  username: string
  password: string
  domain: string
  proxy: string
  extension: string
  is_registered?: boolean
}
export type BoundOptionsType = {
  disposition: string
  reason: string[]
}
// export type callCenterType =
//   | "Online"
//   | "SingleCall"
//   | "Lunch"
//   | "Break"
//   | "Meeting"
//   | "Other"
//   | "Status Unknown"
//   | "Offline"

// Define a type for the slice state
export interface WebPhoneState {
  appState: AppState
  open: boolean
  calls: Call[]
  showCallEnded?: boolean
  currentCallId?: string | undefined
  // resetPreviousSessionModal: boolean
  callState: CallState
  conferenceBridge?: string
  contacts: ContactEntity
  callHistory: ContactEntity
  credentials: Credentials
  previousDialpadBtnClicked: number
  inboundDispositionOptions: BoundOptionsType[]
  outboundDispositionOptions: BoundOptionsType[]
  // callCenterStatus: callCenterType
}
