/* eslint-disable import/prefer-default-export */
import { AvatarProps, message } from "antd"
import { AsYouType, PhoneNumber, parsePhoneNumber } from "libphonenumber-js"

import DefaultUserIcon from "../../Icons/DefaultUser"
import { AttendeesCall, Call, CallControl, Contact } from "../store/type"
import { generateLightColor } from "../../../utils/style"

export const defaultCallControls: CallControl = {
  isMute: false,
  isOnHold: false,
  showDialPad: false,
  isTransferCall: false,
  isAddCallOption: false,
  isRecording: {
    action: "record_off"
  },
  callType: "1:1"
}

export const getDisplayName = (caller: Contact | undefined): string => {
  const name = [caller?.first_name, caller?.last_name].filter((name) => name)

  if (!name.length) return "Unknown"
  return name.join(" ")
}

export const getUserShortName = (caller?: Partial<Contact>): string => {
  const name = [
    caller?.first_name?.slice(0, 1)?.toUpperCase(),
    caller?.last_name?.slice(0, 1)?.toUpperCase()
  ].filter((name) => name)

  if (!name.length) return ""
  if (caller?.first_name === "Unknown") return ""
  return name.join("")
}

export const getFullName = (caller: AttendeesCall) => {
  const callers = Object.keys(caller)

  const getFirstCaller = callers.length ? callers[0] : ""
  const user = caller[getFirstCaller]?.user

  return `${user?.first_name} ${user?.last_name}`
}

export const getFirstAttendee = (caller: AttendeesCall) => {
  const callers = Object.keys(caller)

  const getFirstCaller = callers.length ? callers[0] : ""
  return caller[getFirstCaller]
}

export const getAttendee = (
  caller: AttendeesCall | undefined,
  line?: string
) => {
  if (!caller) return null

  if (!line) {
    return getFirstAttendee(caller)
  }
  return line in caller ? caller[line] : null
}

export const getAttendeeDuration = (line: string, caller: AttendeesCall) => {
  return line in caller
    ? caller[line].duration
    : { hours: 0, minutes: 0, seconds: 0 }
}

export const getUserInfo = (
  extension: string,
  contactLists: Contact[]
): Contact => {
  const user = contactLists.find(
    (contact) =>
      contact.user?.replace("+", "") == extension?.replace("+", "") ||
      contact.work_phone?.replace("+", "") == extension?.replace("+", "") ||
      contact.home_phone?.replace("+", "") == extension?.replace("+", "") ||
      contact.cell_phone?.replace("+", "") == extension?.replace("+", "")
  )

  if (user) return user

  return {
    domain: "",
    email: "",
    first_name: "Unknown",
    last_name: "",
    id: +new Date(),
    last_update: "",
    matrix_user_id: "",
    scope: "user",
    user: "",
    avatar: ""
  }
}

const removeBrackets = (str) => {
  // Check if the string starts with '[' and ends with ']'
  if (str.startsWith("[") && str.endsWith("]")) {
    // Remove the first and last character from the string
    return str.slice(1, -1)
  }
  // If brackets don't exist, return the original string
  return str
}

export const getCurrentCall = (
  callID: string | undefined,
  calls: Call[]
): Call | undefined => {
  if (!callID) return undefined
  return calls.find((call) => callID?.includes(removeBrackets(call.callID)))
}

export const getAttendeesWithCall = (calls: Call[]) =>
  calls.filter((call) => Object.keys(call.attendees).length)

export const callDto = (
  extension: string,
  callID: string,
  contacts: Contact[]
): Call => {
  return {
    ...defaultCallControls,
    callID,
    attendees: {
      ...(extension && {
        [extension]: {
          order: 1,
          event: "connecting",
          extension,
          user: getUserInfo(extension, contacts)
        }
      })
    }
  }
}

export const getUserAvatar = (
  avatar?: string
): { src: string | React.ReactNode } => {
  if (avatar) {
    return { src: avatar }
  }

  return { src: <DefaultUserIcon /> }
}

export const getCallerImage = (user?: Contact): AvatarProps => {
  const { src: avatarSrc } = getUserAvatar(user?.avatar)

  if (!user) {
    return {
      style: {
        backgroundColor: generateLightColor("U"),
        verticalAlign: "middle"
      }
    }
  }

  if (user?.first_name === "Unknown") {
    return {
      src: <DefaultUserIcon />,
      style: {
        backgroundColor: generateLightColor("U"),
        verticalAlign: "middle"
      }
    }
  }

  if (typeof avatarSrc === "string") {
    return {
      src: avatarSrc,
      style: {
        backgroundColor: generateLightColor(user?.first_name),
        verticalAlign: "middle"
      }
    }
  }

  return {
    gap: 4,
    style: {
      backgroundColor: generateLightColor(user?.first_name),
      verticalAlign: "middle"
    }
  }
}

export const getCallIndexById = (callID: string | undefined, calls: Call[]) => {
  if (!callID) return -1

  const callIndex = calls.findIndex((call) =>
    callID?.includes(removeBrackets(call.callID))
  )
  if (callIndex >= 0) return callIndex

  return -1
}

export const removeCallFromCalls = (
  callID: string | undefined,
  calls: Call[]
) => {
  if (!callID) return calls

  return calls.filter((call) => !callID?.includes(removeBrackets(call.callID)))
}

export const removeAttendeeFromCall = (extension: string, call: Call): Call => {
  const { [extension]: attendee, ...callDetail } = call.attendees

  return { ...call, attendees: callDetail }
}

function generateRandomString() {
  const strLength = 20
  let randomString = ""
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < strLength; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length)
    randomString += chars[randomIndex]
  }

  return randomString
}

export const generateCallId = () => {
  const timeStamp = +new Date()
  const hashID = generateRandomString()

  return `${timeStamp}-${hashID}`
}

export const getNextCall = (
  currentCallId: string,
  calls: Call[]
): Call | undefined => {
  const holdCalls = calls.filter((call) => call.isOnHold)
  const getCurrentCallIndex = getCallIndexById(currentCallId, holdCalls)

  if (getCurrentCallIndex === holdCalls.length - 1) {
    return holdCalls[0]
  }

  return holdCalls[getCurrentCallIndex + 1]
}

export const sleep = (milliseconds = 300) => {
  const startTime = new Date().getTime()
  while (new Date().getTime() - startTime < milliseconds) {
    // call callback if required
  }
}

export const getAllAttendeesFromCalls = (calls: Call[]): string[] => {
  return calls.map((call) => Object.keys(call.attendees || {})).flat()
}

export const searchContacts = (
  search: string,
  contacts: Contact[],
  calls?: Call[],
  sorted?: boolean,
  removedDuplicate?: boolean
) => {
  const searchQuery = search.toLocaleLowerCase()?.trim()
  const callAttendees = getAllAttendeesFromCalls(calls ?? [])

  let filterContact = contacts.filter(
    (contact) => !callAttendees.includes(contact.user)
  )

  if (sorted) {
    filterContact = filterContact.sort((a, b) =>
      a.first_name > b.first_name ? 0 : -1
    )
  }
  if (removedDuplicate) {
    filterContact = [...new Map(filterContact.map((m) => [m.user, m])).values()]
  }

  if (!searchQuery.length) {
    return filterContact
  }

  return filterContact.filter((contact) => {
    const searchLowerCase = searchQuery.toLowerCase()
    const displayName = (getDisplayName(contact) || "").toLowerCase()

    const isFirstNameMatch = contact.first_name
      ?.toLowerCase()
      ?.includes(searchLowerCase)
    const isLastNameMatch = contact.last_name
      ?.toLowerCase()
      ?.includes(searchLowerCase)
    const isDisplayNameMatch = displayName.includes(searchLowerCase)
    const isDisplayNameNoSpacesMatch = displayName
      .replace(/\s/g, "")
      .includes(searchLowerCase)
    const isUserMatch = contact.user
      ?.toLowerCase()
      ?.replace(/[\D]/g, "")
      ?.includes(searchLowerCase)

    return (
      isUserMatch ||
      isLastNameMatch ||
      isFirstNameMatch ||
      isDisplayNameMatch ||
      isDisplayNameNoSpacesMatch
    )
  })
}

type GetConferenceAttendees = { attendees: Contact; avatar: AvatarProps }
export const getConferenceAttendees = (
  call: Call
): GetConferenceAttendees[] => {
  const { attendees } = call
  const attendeeByExtension = Object.keys(call.attendees)

  return attendeeByExtension.reduce((a, b) => {
    const fullName = attendees[b].user
    const { event } = attendees[b]
    const { startTime } = attendees[b]
    const avatar = getCallerImage(attendees[b].user)

    a.push({ attendees: fullName, avatar, event, startTime })

    return a
  }, [] as GetConferenceAttendees[])
}

export const normalizePhoneNumber = (number: string) => {
  const allowedCharacters = /[^0-9*#+]/g
  return number.replace(allowedCharacters, "")
}

export const formatNumber = (phone: string, isMembers?: "room" | "members") => {
  if (!phone && isMembers) return
  try {
    // Check if the cleaned phone includes '*' or '#'
    if (phone.includes("*") || phone.includes("#")) {
      return phone
    }

    // Remove all non-digit characters from the input
    let cleaned = phone
    let phoneNumber: string
    let code
    let number: PhoneNumber
    if (
      cleaned.startsWith("1", 0) &&
      cleaned.length > 7 &&
      !cleaned.includes("+")
    ) {
      return new AsYouType("US").input(cleaned)
    }

    if (!cleaned.includes("+") && cleaned.length > 11) {
      cleaned = `+${cleaned}`
    }

    if (
      (cleaned.includes("+") && cleaned.length > 7 && cleaned.length < 20) ||
      (cleaned.substring(0, 3) === "011" &&
        cleaned.length > 7 &&
        cleaned.length < 20)
    ) {
      let checkCleaned
      if (cleaned.substring(0, 3) === "011") {
        checkCleaned = `+${cleaned.substring(3)}`
      }
      number = parsePhoneNumber(checkCleaned ? checkCleaned : cleaned)

      const [countryCode, ...num] = number?.formatInternational().split(" ")
      code = countryCode
      phoneNumber = num.join().replace(",", "")
    } else {
      code = ""
      phoneNumber = cleaned.replace(",", "")
    }

    const len = phoneNumber.length

    const getPhoneNum = () => {
      if (len < 9) {
        return phoneNumber
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber
        .slice(3, 6)
        .replace(",", "")}-${phoneNumber.slice(6, len)}`.replace(",", "")
    }

    if (code) {
      return `${code} ${getPhoneNum().replace(",", "")}`
    }
    return getPhoneNum().replace(",", "")
  } catch (error) {
    message.error("Invalid Number!")
    console.warn(error)
    return ""
  }
}
