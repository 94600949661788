/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { message } from "antd"
import { get, post } from "../../../services/api"
import { setCredentialIntoLocalStorage } from "../utils/storage"
import { WebPhoneAPI } from "../type"
import { normalizePhoneNumber } from "../utils/call"

declare const window: WebPhoneAPI

export const getContactLists = createAsyncThunk(
  "webPhone/getContactLists",
  async (isMatrix: boolean, { rejectWithValue }) => {
    try {
      const query = isMatrix ? "&is_metrix=true" : "&is_metrix=false"
      const { data } = await get(`/api/v1/auth/contacts/?${query}`)
      return data?.data?.results ?? []
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)
export const getDispositionOption = createAsyncThunk(
  "webPhone/getDispositionOption",
  async (type: "Inbound" | "Outbound", { rejectWithValue }) => {
    try {
      const response = await get(
        `/api/v1/soft-phone/call-history/fetch_disposition/?type=${type}`
      )
      return { data: response.data?.data ?? [], boundType: type }
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)
export const showMessageOnCallChange = createAsyncThunk(
  "webPhone/showNotifOnCallEnd",
  async (reason: string, { rejectWithValue }) => {
    try {
      return reason
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)

export const getCallHistory = createAsyncThunk(
  "webPhone/getCallHistory",
  async (_, { rejectWithValue }) => {
    try {
      const startDate = dayjs()
        .add(-7, "day")
        .startOf("day")
        .format("YYYY-MM-DD hh:mm:ss")
      const endDate = dayjs().format("YYYY-MM-DD HH:mm:ss")
      const { data } = await get(
        `/api/v1/soft-phone/call-history/?start_date=${startDate}&end_date=${endDate}`
      )

      return (data?.data?.results ?? []).map((user: any) => ({
        avatar: user.avatar || "",
        domain: user?.phone_number?.split("@")[1],
        email: "",
        first_name: user?.name?.split(" ")[0],
        id: "",
        last_name: user?.name?.split(" ")[1],
        last_update: "",
        matrix_user_id: user?.cdr_id,
        scope: "",
        user: normalizePhoneNumber(user.phone_number),
        flag: user?.type === "Outgoing" ? 1 : user?.type === "Missed" ? 3 : 2
      }))
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)

export const getWebPhoneCredential = createAsyncThunk(
  "webPhone/getWebPhoneCredential",
  // async (args: boolean = false, { rejectWithValue }) => {
  async (_, { rejectWithValue }) => {
    try {
      const setParameter = window.webphone_api.setparameter
      const { data } = await get("/api/v1/soft-phone/device/?device_suffix=ow")
      // const { data } = args
      //   ? await get("/api/v1/soft-phone/device/?device_suffix=ow&is_reset=true")
      //   : await get("/api/v1/soft-phone/device/?device_suffix=ow")
      const credentials = data?.data

      const serverURI = `${credentials.domain}.${credentials.proxy_server_url}:5060`
      const webRTCAddress = `wss://${credentials.proxy_server_url}:9002`

      setParameter("serveraddress", serverURI)
      setParameter("webrtcserveraddress", webRTCAddress)
      setParameter("username", credentials?.username)
      setParameter("password", credentials?.authentication_key)
      setParameter("registerinterval", 60)
      setParameter("useragent", "OneCloud Connect Webphone")

      const wpCredential = {
        username: credentials?.username,
        password: credentials?.authentication_key,
        domain: credentials?.domain,
        proxy: credentials?.proxy_server_url,
        extension: credentials?.username?.replace(/\D/g, ""),
        is_registered: credentials?.is_registered
      }

      setCredentialIntoLocalStorage(wpCredential)
      return wpCredential
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)

export const getConferenceBridgeAPI = async (callids: string) => {
  try {
    const { data } = await post("/api/v1/soft-phone/merge-call/", { callids })
    const response = data?.data

    return response?.conference_bridge || ""
  } catch (error: any) {
    message.error("Unable to merge calls")
    return ""
  }
}

export const getCallRecordingAPI = async (action: string, callID?: string) => {
  try {
    const { data, status } = await post(
      "/api/v1/soft-phone/call-history/record_call/",
      {
        action,
        callid: callID?.replace(/\[|\]/g, "")
      }
    )
    if (status === 200) {
      message.success(
        `Recording has ${
          action === "record_on"
            ? "started"
            : action === "record_pause"
            ? "paused"
            : action === "record_unpause"
            ? "resumed"
            : "saved"
        }`
      )
    }
    return data
  } catch (error) {
    console.warn(">> error", error)
    message.error("Something went wrong in Recording")
    return ""
  }
}

export const holdCallAPI = async (action: string, callID?: string) => {
  try {
    const { data } = await post("/api/v1/soft-phone/call-hold/", {
      action,
      callid: callID?.replace(/\[|\]/g, "")
    })
    return data
  } catch (error) {
    message.error("Something went wrong in hold call")
    return ""
  }
}

type CallRecordingAPI = { action: string; callID?: string }
export const getCallRecording = createAsyncThunk(
  "webPhone/getCallRecording",
  async (payload: CallRecordingAPI, { rejectWithValue }) => {
    try {
      const { action, callID } = payload
      const data = await getCallRecordingAPI(action, callID)
      return payload
    } catch (error: any) {
      return rejectWithValue(error?.message)
    }
  }
)
