/* eslint-disable */
import { ButtonType } from "antd/es/button"
import { ButtonStyle, LinkStyle } from "./Button.style"
import { ButtonProps } from "antd"

interface CustomeProps {
  href?: string
  type?: ButtonType | "red"
}
type Props = ButtonProps & CustomeProps
const Button = (props: Props) => {
  const {
    children,
    type,
    icon,
    disabled,
    size,
    href,
    block,
    loading,
    target,
    onClick,
    htmlType,
    className,
    shape,
    danger,
    onMouseDown,
    onMouseUp
  } = props
  return (
    <>
      {type !== "link" || target ? (
        <ButtonStyle
          type={type}
          icon={icon}
          disabled={disabled}
          size={size}
          href={href}
          block={block}
          loading={loading}
          target={target}
          onClick={onClick}
          htmlType={htmlType}
          className={className}
          shape={shape}
          danger={danger}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          {children}
        </ButtonStyle>
      ) : (
        <LinkStyle
          className={className ? `${className} linkstyle` : "linkstyle"}
          to={href}
          onClick={onClick}
        >
          <span>{icon}</span>
          {children}
        </LinkStyle>
      )}
    </>
  )
}

export default Button
