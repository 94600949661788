import { WebPhoneAPI } from "../type"

declare const window: WebPhoneAPI

export const setCredentialIntoLocalStorage = (credential: any) => {
  const credentialInStr = JSON.stringify(credential)
  localStorage.setItem("webphone-credential", credentialInStr)
}

export const getCredentialFromLocalStorage = () => {
  const credentialInStr = localStorage.getItem("webphone-credential")
  if (!credentialInStr) {
    return null
  }
  const credential = JSON.parse(credentialInStr)
  if (
    !credential.username ||
    !credential.password ||
    !credential.domain ||
    !credential.proxy
  ) {
    return null
  }

  return credential
}

export const removeWPCredentialFromLocalStorage = () => {
  localStorage.removeItem("webphone-credential")
  const setParameter = window.webphone_api.setparameter
  setParameter("displayname", "")
  setParameter("username", "")
  setParameter("password", "")
  setParameter("serveraddress", "")
  setParameter("webrtcserveraddress", "")
  window.webphone_api.unregister()
  window.webphone_api.delsettings(1)
}
